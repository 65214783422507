import StakeSummery from "../../component/stake/StakeSummery";
import beautiesImg from "../../assets/img/monaki_beauties_2.png"
import scammersImg from "../../assets/img/monaki_scammers_black.png"
import StakeContainer from "../../component/stake/StakeContainer";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import StakeSummeryScammers from "../../component/stake/StakeSummeryScammers";
import StakeContainerScammers from "../../component/stake/StakeContainerScammers";
  

const Stake = () =>{
    
    const location = useLocation();
    const collection = location.pathname.split('/')[1];
     
    return(
        <> 
         <div className="home site-paddding mb-5">
                <div className="container-fluid padding-5em head-section-1">
                    <h2 className="home-title">STAKING WORLD {collection == 'beauties'? 'BEAUTIES' : 'THE SCAMMERS'}</h2>
                </div>
                
                <div className="container-fluid ">
                <div className="row ">
                    {
                        collection == 'beauties'?
                        <><StakeSummery /> 
                        <StakeContainer /></>
                        : <><StakeSummeryScammers /><StakeContainerScammers /></>
                    }
                    
                    
                </div>
                </div>
        </div>
        <img src={collection == 'beauties'? beautiesImg : scammersImg} alt=""  className="d-inline-block align-text-top monaki-img" />
    <br/>
        </>
    );
}
export default Stake;