
import loadingNFT from "../../assets/img/loading.gif";
import { useEffect, useState } from 'react';
import LoaderView from "./Loader/Loader";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const MynftSignle = (props: any) =>{
   

    const {nft, handleClick, staking} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [imgLoading, setImgLoading] = useState<boolean>(false);
    /* @ts-ignore */

    const imageLoaded = (isLoaded : boolean) => {
      setImgLoading(isLoaded);
    }
 
    return(<>
    {loading && <LoaderView /> }
    <div className="card col align-self-start transparent">
        <div className="img-placeholder" onClick={()=> {
          if(!staking) handleClick();
        }}>
          <img src={loadingNFT} className="img-fluid rounded-3 " id="image" style={{"display": imgLoading? "none" : "initial"}} />
          <img src={nft.metadata.image} onLoad={() => imageLoaded(true)} alt={nft.metadata.name} className="img-fluid rounded-3 " id="image" style={{"display": imgLoading? "initial" : "none"}} />
          { staking && <span className="overlay-verify">Verifying...</span> }
        </div>
    </div>
    </>);
}
export default MynftSignle;
