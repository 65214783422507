//@ts-nocheck
import { useEffect, useState } from 'react';
import { StakingMaster, createRewardValue } from '../contractsScammers/StakingMaster';
import { NFTItem } from '../contractsScammers/NFTItem';
import { StakingHelper } from '../contractsScammers/StakingHelper';
import { useTonClient } from './useTonClient';
import { useAsyncInitialize } from './useAsyncInitialize';
import { Address, OpenedContract, Dictionary, toNano, beginCell, address, fromNano} from '@ton/core';
import { useTonConnect } from './useTonConnect';
import { useTonWallet } from '@tonconnect/ui-react'
import {  init, setStakedNFT} from "../store/contract/contractSlice";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getMyPoolNfts,getMyPoolNftsScammers } from '../api/api';
import nftsJson from "../files/nfts.json"
import ScammersNftsTestJson from "../files/ScammersNftsTest.json"
import ScammersNftsJson from "../files/ScammersNfts.json"
import { send } from 'process';

export function useStakingContractScammers() {
  const dispatch = useDispatch();
  const client = useTonClient();
  const [staked, setStaked] = useState<Dictionary<Address, Address>>();
  const { sender } = useTonConnect();
  const wallet = useTonWallet(); 
  // Test EQBpT2HwBN_w1SkUAGNJ6xZ3lFCXYwsgUjE2Qd73ihp5mZMw
  // new Test EQAxpxBYBzvjjiDMEc8H65XCd3UPhhFNHuIx_kIHamdeaMEI
  // LIVE EQAS6_B_BAMb0cI_fc70C8SP7i1yXwy5vhmxC1fec4SDXA1_
  const [contractAddress, setContractAddress] = useState<Address>(Address.parse("EQAS6_B_BAMb0cI_fc70C8SP7i1yXwy5vhmxC1fec4SDXA1_")) ;

  const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

  const stakingContract = useAsyncInitialize(async () => {
    if (!client) return;
    const contract = new StakingMaster(
      contractAddress // replace with your address from tutorial 2 step 8
    );
    // client.open(contract) as OpenedContract<StakingMaster>;
    
    const isDeployed =  client.isContractDeployed(contractAddress);
    // console.log("IsDeploy:",isDeployed)
    
    return client.open(contract) as OpenedContract<StakingMaster>;
  }, [client]);

  // const stakingContract = useAsyncInitialize(async () => {
  //   if (!client) return;
    
  //   const stakingMaster = client.open(
  //       StakingMaster.createFromAddress(Address.parse('kQAxpxBYBzvjjiDMEc8H65XCd3UPhhFNHuIx_kIHamdeaHqC'))
  //   ) as OpenedContract<StakingMaster>;
  //   console.log('CONTRACT BELOW:')
  //   console.log(await stakingMaster.getContractData());
  //   return stakingMaster;
  // }, [client])

  // useEffect(() => {
  //   // async function getValue() {
  //   //   if (!stakingContract) return;
  //   //   // setStaked(null);
  //   //   // const stakedData = await stakingContract?.getStakedItems();
  //   //   // console.log("get staked",stakedData)
  //   //   // await sleep(5000)
  //   //   // setStaked(stakedData);
  //   // }
  //   // getValue();
  // }, [stakingContract]);

  return {
    // get stake nfts data
    address: stakingContract?.address.toString(),
    staked: stakingContract?.getContractData(),
    // addNftToContract: async (from, to) => {
    //   if (!stakingContract) return;
    //   const contract = await stakingContract.getContractData();
    //   const contractNfts = contract.items;
    //   const nfts = contractNfts.keys().map(item=> item.toRawString());
    //   let pendingNfts = [];
    //      for(const item of ScammersNftsJson){
    //       if(!nfts.includes(item.address_raw)){
    //         pendingNfts.push(item);
    //       }
    //     }


    //   // const nfts = ScammersNftsJson.slice(from,to);
    //   console.log("NFTs:",pendingNfts)
    //   const items = Dictionary.empty(Dictionary.Keys.Address(), Dictionary.Values.BigUint(16));
    //   pendingNfts.forEach((nft) => {
    //     items.set(Address.parse(nft.address),BigInt(nft.rarityType))
    //   })
    //   console.log("ITEMS: ",items);
    //   stakingContract?.sendAdminAddItems(
    //       sender,
    //       toNano('1'),
    //       11n,
    //       items
    //   ).then((resData)=>{
    //     return resData;
    //   });

    // },

    // addRarity: async(rarityType, reward)=>{
    //   if (!stakingContract) return;
    //   let rarity = Dictionary.empty(Dictionary.Keys.BigUint(16), createRewardValue());
    //   // rarity = rarity.set(BigInt(rarityType), { commonReward: toNano(reward.toString()), boostReward: toNano(0) });
    //   rarity = rarity.set(BigInt(2), { commonReward: toNano('0.75'), boostReward: toNano((0.35  / (86400)).toFixed(9)) });
    //   await stakingContract?.sendAdminAddRarity(sender, toNano('0.05'), BigInt(2), rarity);
    //   console.log("Rarity Added: ", rarityType, reward)
    // },

    // removeRarity: async (rarityType) => {
    //   if (!stakingContract) return;
    //   await stakingContract?.sendAdminRemoveRarity(
    //     sender,
    //     toNano('0.05'),
    //     BigInt(rarityType),
    //     (
    //       await stakingContract?.getContractData()
    //     ).rarity
    //   )

    //   console.log("Rarity Removed: ", rarityType);
    // },
    
    // getNftsList: async() => {
    //   if (!stakingContract) return;
    //   const contractData = (await stakingContract.getContractData());
    //   console.log(contractData);
    // },

    // create staking
    createStake: (nft: string, days: number) => {
      const resDays = [7,14,30];
      if (!client) return;
      if(!nft) return;
      if(!resDays.includes(days)) return;
      const NFTContract = new NFTItem(
        Address.parse(nft) 
      );
      const item =  client.open(NFTContract) as OpenedContract<NFTItem>;
      item.sendTransfer(
        sender,
        toNano('0.3'),
        contractAddress,
        beginCell().storeUint(0x429c67c7, 32).storeUint(days, 8).endCell(),
        toNano('0.15')
      ).then((result) => {
        return result;
      })
      
  },
    
  getStakedNftsByUser: async() => {
    console.log("Calling")
    const items = [];
    if (!client) return;
    if(!wallet) return;
    const nfts = await stakingContract?.getItemsStakedByUser(Address.parse(wallet?.account?.address),(
      await stakingContract?.getContractData()
    ).stakedItems);
    console.log(nfts);

    for (const key of nfts) {
      // console.log(key)
      const helper = await stakingContract?.getHelper(key);
      if(!helper) return;
      const helperContract =  client.open(helper) as OpenedContract<StakingHelper>;
      
      // const days = await helperContract.getOption() ;
      // const stakedAt = await helperContract.getStakedAt();
      // const claimedAt = await helperContract.getClaimedAt();
      const data = await helperContract.getContractData();
      // const [days, stakedAt, claimedAt] = await Promise.all([
      //   helperContract.getOption(), 
      //    helperContract.getStakedAt(),
      //    helperContract.getClaimedAt()
      // ]);
      
      const timePassed = Math.floor((Date.now() / 1000)) - data.claimedAt;
      const rewards = await stakingContract?.getEstimatedRewards(key, timePassed);
      const obj = {
        address: key?.toRawString(),
        stakedAt: data.stakedAt * 1000,
        unstakeAt: (data.stakedAt + 86400 * data.option) * 1000,
        days: data.option,
        rewards: Number(fromNano(rewards))
      };

      items.push(obj);
    }
  
    await Promise.all(nfts);
    // console.log('All done!');
    console.log("NFTS:",items)
    getMyPoolNftsScammers(items);

  },

  sendClaim: async(nft:string, isUnstake: boolean) => {
    if (!client) return;
    if(!wallet) return;
    const helper = await stakingContract?.getHelper(Address.parse(nft));
    if(!helper) return;
    var promise = new Promise( async(resolve, reject) => {
      const helperContract =  client.open(helper) as OpenedContract<StakingHelper>;

      var startTime = new Date().getTime();
      await helperContract.sendClaim(sender, toNano('0.5'), 12n, isUnstake);
      setInterval(resolve(true),10000);
     
    })

    promise.then((data) => {
      return true;
    });


  }

  };
}
