//@ts-nocheck
import { useEffect, useState } from 'react';
import { StakingMaster } from '../contracts/StakingMaster';
import { NFTItem } from '../contracts/NFTItem';
import { StakingHelper } from '../contracts/StakingHelper';
import { useTonClient } from './useTonClient';
import { useAsyncInitialize } from './useAsyncInitialize';
import { Address, OpenedContract, Dictionary, toNano, beginCell, address, fromNano} from '@ton/core';
import { useTonConnect } from './useTonConnect';
import { useTonWallet } from '@tonconnect/ui-react'
import {  init, setStakedNFT} from "../store/contract/contractSlice";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getMyPoolNfts } from '../api/api';
import nftsJson from "../files/nfts.json"
import MonakiNfts from '../files/MonakiNfts.json'
import DragonNfts from '../files/DragonNfts.json'

export function useStakingContract() {
  const dispatch = useDispatch();
  const client = useTonClient();
  const [staked, setStaked] = useState<Dictionary<Address, Address>>();
  const { sender } = useTonConnect();
  const wallet = useTonWallet(); 
  // old 0QBz7Elzh06jQVjmqaxpab_d01cSJ0usLP5sOXdv7XB_3UXD
  // new test EQAmV00xzbgmFETP3Uk4yQcFcI6rzgiR7zQKEMu0t5Sig9En
  // new Nano EQC0LGshKZNoUMGu3Wdy-vGfswnIn18OH_EcJZGiLUW11ZSr
  // live EQD_B2BshgjWYw65711nQ91Nyw0b8u9zteR1_eYE0FcW7WYL
  const [contractAddress, setContractAddress] = useState<Address>(Address.parse("EQD_B2BshgjWYw65711nQ91Nyw0b8u9zteR1_eYE0FcW7WYL")) ;

  const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

  const stakingContract = useAsyncInitialize(async () => {
    if (!client) return;
    const contract = new StakingMaster(
      contractAddress // replace with your address from tutorial 2 step 8
    );
    // client.open(contract) as OpenedContract<StakingMaster>;
    
    // const isDeployed =  client.isContractDeployed(contractAddress);
    
    
    return client.open(contract) as OpenedContract<StakingMaster>;
  }, [client]);

  useEffect(() => {
    async function getValue() {
      if (!stakingContract) return;
      // setStaked(null);
      // const stakedData = await stakingContract?.getStakedItems();
      // console.log("get staked",stakedData)
      // await sleep(5000)
      // setStaked(stakedData);
    }
    getValue();
  }, [stakingContract]);

  return {
    // get stake nfts data
    staked: stakingContract?.getStakedItems(),
    addNftToContract: (nft, rewards) => {
      if (!stakingContract) return;
      
      // const nfts = nftsJson.slice(from,to);
      // const items = Dictionary.empty(Dictionary.Keys.Address(), Dictionary.Values.BigVarUint(4));
      // nfts.forEach((nft) => {
      //   items.set(Address.parse(nft.address),toNano(nft.rewards))
      // })

      const items = Dictionary.empty(Dictionary.Keys.Address(), Dictionary.Values.BigVarUint(4)).set(Address.parse(nft),toNano(rewards))
      
      stakingContract?.sendAdminAddItems(
          sender,
          toNano('0.1'),
          11n,
          items
      ).then((resData)=>{
        // console.log("CONTRACT RESPONSE: ",resData)
        return resData;
      });

      // return items;
    },
    removeContractNft: async(nft) => {
      const nfts = [];
      nfts.push(Address.parse(nft))
      if (!stakingContract) return;
      stakingContract?.sendAdminRemoveItems(
        sender,
        toNano('0.05'),
        12n,
        nfts
      ).then((resData)=>{
        // console.log("CONTRACT RES:",resData);
        return resData;
      })
    },
    getNftsList: () => {
      if (!stakingContract) return;
      stakingContract?.getItems(sender).then((resData)=>{
        console.log("Contract NFTs", resData);
        // let database = MonakiNfts.map(ditem => ditem.address_raw);
        //database.includes()
        // console.log(database);
        // let i = 0;
        // for(const item of resData){
        //   console.log("rewards:",fromNano(item[1]))
        //   i++;
        //   if(i == 100) break;
          // // console.log("Item", );
          // if(!database.includes(item[0].toRawString())){
          //   console.log(false, item[0].toRawString())
          // }else{
          //   delete database(item[0].toRawString())
          //   // database.delete(item[0].toRawString());
          //   console.log(true)
          // }
        // }
        // const nfts = resData.keys().map(item=> item.toRawString());

        
      })
    },
    getContractNfts: async () => {
      let start = Date.now();
      const items = [];
      if (!client) return;
      if(!wallet) return;
      let i = 0;
      // console.log("Start");
      const nfts = await stakingContract?.getStakedItems();
      
      for (const [key, value] of nfts) {
        
        i++
        const helper = await stakingContract?.getHelper(key);
        if(!helper) return;
        const helperContract =  client.open(helper) as OpenedContract<StakingHelper>;
        
        // const days = await helperContract.getOption() ;
        // console.log("Process....1");
        // const stakedAt = await helperContract.getStakedAt();
        // console.log("Process....2");
        // const claimedAt = await helperContract.getClaimedAt();
        // console.log("Process....3");
        const data = await helperContract.getContractData();
        // const [days, stakedAt, claimedAt] = await Promise.all([
        //   helperContract.getOption(), 
        //    helperContract.getStakedAt(),
        //    helperContract.getClaimedAt()
        // ]);
        // console.log(`Process....${i}`);
        
        const timePassed = Math.floor((Date.now() / 1000)) - data.claimedAt;
        const rewards = await stakingContract?.getEstimatedRewards(key, timePassed);
        const obj = {
          address: key?.toRawString(),
          stakedAt: data.stakedAt * 1000,
          unstakeAt: (data.stakedAt + 86400 * data.option) * 1000,
          days: data.option,
          rewards: Number(rewards) //Number(fromNano(rewards))
        };
        // console.log(i)
        items.push(obj);
        if(i == 80) break;
      }
    
      await Promise.all(items);
      // console.log('All done!', items);
      
      let timeTaken = Date.now() - start;
      // console.log("Total time taken : " + timeTaken + " milliseconds");
      return items;
    },
    // withdrawTokens: () => {
    //   if (!stakingContract) return;
    //   stakingContract?.sendAdminJettonsWithdrawal( 
    //     sender,
    //     toNano('0.15'),
    //     99n,
    //     10
    //   ).then((resData)=>{
    //     console.log("CONTRACT RES:", resData);
    //     return resData;
    //   })
    // },

    // get contract address
    address: stakingContract?.address.toString(),

    // create staking
    createStake: (nft: string, days: number) => {
        const resDays = [7,14,30];
        if (!client) return;
        if(!nft) return;
        if(!resDays.includes(days)) return;
        const NFTContract = new NFTItem(
          Address.parse(nft) 
        );
        const item =  client.open(NFTContract) as OpenedContract<NFTItem>;
        item.sendTransfer(
          sender,
          toNano('0.3'),
          contractAddress,
          beginCell().storeUint(0x429c67c7, 32).storeUint(days, 8).endCell(),
          toNano('0.15')
        ).then((result) => {
          return result;
        })
        
    },

    // get my staked nft
    getStakedNftsByUser: async() => {
      const items = [];
      if (!client) return;
      if(!wallet) return;
      const nfts = await stakingContract?.getItemsStakedByUser(Address.parse(wallet?.account?.address));
      console.log("call getStakeNFTByUser")
      for (const key of nfts) {
        // console.log(key)
        const helper = await stakingContract?.getHelper(key);
        if(!helper) return;
        const helperContract =  client.open(helper) as OpenedContract<StakingHelper>;
        
        const data = await helperContract.getContractData();
        
        const timePassed = Math.floor((Date.now() / 1000)) - data.claimedAt;
        const rewards = await stakingContract?.getEstimatedRewards(key, timePassed);
        const obj = {
          address: key?.toRawString(),
          stakedAt: data.stakedAt * 1000,
          unstakeAt: (data.stakedAt + 86400 * data.option) * 1000,
          days: data.option,
          rewards: Number(fromNano(rewards))
        };

        items.push(obj);
      }
    
      await Promise.all(items);
      getMyPoolNfts(items);

    },

    sendClaim: async (nft:string, isUnstake: boolean) => {
      if (!client) return;
      if(!wallet) return;
      const helper = await stakingContract?.getHelper(Address.parse(nft));
      if(!helper) return;
      var promise = new Promise( async(resolve, reject) => {
        const helperContract =  client.open(helper) as OpenedContract<StakingHelper>;

        var startTime = new Date().getTime();
        await helperContract.sendClaim(sender, toNano('0.5'), 12n, isUnstake);
        setInterval(resolve(true),10000);
       
      })

      promise.then((data) => {
        return true;
      });


    }


  };
}
