//@ts-nocheck
import Logo from "../../assets/img/logo.png";
import loadingNFT from "../../assets/img/loading.gif";
import { useEffect, useState } from 'react';
import LoaderView from "./Loader/Loader";
import DateTme from "../common/DateTme";
// import moment from "moment";
import moment from 'moment-timezone';
moment.tz.setDefault('UTC');
import { useStakingContractScammers } from "../../hooks/useStakingContractScammers";


const StakeNFTScammers = (props: any) =>{

    /* @ts-ignore */
    const [loading, setLoading] = useState<boolean>(false);
    const [imgLoading, setImgLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<any>(true);
    const [inProcess, setInProcess] = useState<any>(false);
    const { getStakedNftsByUser, sendClaim } = useStakingContractScammers();


    useEffect(()=>{
      const currentDate = moment.utc(); 
      const endDate = moment(props.item.unstakeAt);
      const remainingSeconds = endDate.diff(currentDate, 'seconds', true);
    
      if(remainingSeconds < 0){
        // console.log("Claim open", remainingSeconds, endDate)
        setDisabled(false)
      }else{
        // console.log("Claim Closed", remainingSeconds, endDate)
      }
      if(!props.item.rewards && inProcess){
        setInProcess(false)
      }
    },[props.item])

    const imageLoaded = (isLoaded : boolean) => {
        setImgLoading(isLoaded);
    }
 
    const handleUnstake = async (item, isUnstake) => {
        setInProcess(true)
        const address = item.address;
        sendClaim(address, isUnstake).then((resData)=>{
          setTimeout(()=>{
            getStakedNftsByUser();
            fetchNfts();
            setInProcess(false)
          }, 30000)
        })
    }
 
    

    return(<>
    {loading && <LoaderView /> }
        <div className="card col align-self-start">
            <div className="mb-2">
                <img src={Logo} alt="avatar" className="avatar border border-white rounded-circle" />
                <span className="creator ms-3">
                    {props.item.name}
                </span>
              </div>
            <div className="img-placeholder">
              <img src={loadingNFT} className="img-fluid rounded-3 " id="image" style={{"display": imgLoading? "none" : "initial"}} />
              <img src={props.item.image} onLoad={() => imageLoaded(true)} alt="Monaki: beautes #12" className="img-fluid rounded-3 " id="image" style={{"display": imgLoading? "initial" : "none"}} />
            </div>
            <div className="card-body p-0">
              <h5 className="card-title mt-2">{props.item.name}</h5>
              <p className="card-text" style={{margin:"0 !important"}}>MONAKI: The Scammers</p>
              <h6 className="card-reward mt-2">Reward: {props.item.rewards} $MONK</h6>
              <h6 className="card-reward mt-2">Unlock At: <DateTme date={props.item.unstakeAt} setDisabled={(isDisabled)=>{
                if(!inProcess){
                  setDisabled(isDisabled);
                }
              }}/></h6>
              <center>
              {
                props.item.rewards && !inProcess && !disabled? 
                <><button className="btn btn-black mb-2" onClick={()=>{handleUnstake(props.item, true)}} disabled={disabled} >CLAIM & UNSTAKE</button>
                <button className="btn btn-black" onClick={()=>{handleUnstake(props.item, false)}} disabled={disabled} >CLAIM & REPEAT</button>
                </>
                : inProcess? 
                <button className="btn btn-black" disabled={true} >In Process...</button> : ''
              }
              </center>
              {/* <center>
                <button className="btn btn-black" onClick={()=>{claimRewardHandler()}} disabled={item.claimed} >CLAIM REWARD</button>
              </center> */}
              {/* {unstakeCheck && <center><button className="btn btn-black mt-2" onClick={()=>{unStakedHandler()}}>Unstake</button></center>} */}
              {/* {item.lock_till}</Moment> */}
              {/* <Moment unix durationFromNow /> {new Date(item.lock_till).getTime()}</Moment> */}
            </div>
        </div>
    </>);
}
export default StakeNFTScammers;