import MynftScammers from "./MynftScammers";
import { useEffect, useState } from 'react';
import LoaderView from "./Loader/Loader";
import NftsContainerScammers from "./NftsContainerScammers";

const StakeContainerScammers = () =>{
    console.log("SCAMMERS")
    const [days, setDays] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    console.log("Days",days);

    return(<>
    {loading && <LoaderView /> }
          <div className="col-lg-8 staking-block">
              <div className="row">
              <div className="float-end-parent">
                  <div className="nav-buttons-parent float-md-end float-none">
                      <button className={days === 0? "btn btn-transparent ms-3 active" :"btn btn-transparent-black ms-3"} onClick={() => setDays(0)}>MY NFTs</button>
                      <button className={days === 7? "btn btn-transparent ms-3 active" :"btn btn-transparent-black ms-3"} onClick={() => setDays(7)} >7 DAYS</button>
                      <button className={days === 14? "btn btn-transparent ms-3 active" :"btn btn-transparent-black ms-3"} onClick={() => setDays(14)} >14 DAYS</button>
                      <button className={days === 30? "btn btn-transparent ms-3 active" :"btn btn-transparent-black ms-3"} onClick={() => setDays(30)} >30 DAYS</button>
                  </div>
              </div>
              
              <div className={days? "col-lg-12" : "col-lg-12 mynft"}>
                  <div className="row row-cols-1 row-cols-lg-5 ">
                        {
                            days? <NftsContainerScammers days={days} key={days} /> : <MynftScammers/>
                        }
                  </div>
              </div>

              </div>
          </div>
    
    </>);
};
export default StakeContainerScammers;
