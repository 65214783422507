//@ts-nocheck
import monaki from "./../../assets/img/monaki_home.png";
import frame from "./../../assets/img/back_frame.png";
import "./HomeStyle.css";
import { useState, useEffect } from 'react';
import { useStakingContract } from "../../hooks/useStakingContract";

import {Link} from "react-router-dom";

const Home = () =>{
    
  const { staked } = useStakingContract();
    const [data, setData] = useState({
        total: 999,
        staked: 0,
        percentage:0
    });

    useEffect(()=>{
        staked?.then((totalStakedData)=> {
            const totalNFT = 999;
            if(totalStakedData.size){
                const totalStaked = totalStakedData.size;
                const percentage = (totalStaked/totalNFT)*100;
                
                const obj = {
                    total: totalNFT, staked: totalStaked, percentage: percentage.toFixed(2)
                }
                setData(obj)
            }
            
        })
        // staked().then((resData)=>{
        //     console.log("Staked",resData)
        //     // if(resData.success){
        //     //     setData(resData.data)
        //     // }
        // })
    },[staked])
    
    return(
        <>
            <div className="home site-paddding">
                <div className="container-fluid padding-5em home-section-1">
                    <h2 className="home-title">STAKE YOUR NFTs & MINE $MONK</h2>
                    <p>We will automatically stake the MONAKI NFTs in your wallet and reward
                    <br />$MONK to your account after connecting wallet.</p>
                    <Link to="/beauties" className="btn btn-black">BEAUTIES</Link>
                    <Link to="/scammers" className="btn btn-black ms-3">THE SCAMMERS</Link>
                </div>
                
            </div>
            <div className="container-fluid align-items-center" style={{padding:"20px"}}> 
                <img src={monaki} alt=""  className="d-inline-block align-text-top monaki-img" />
            </div>
            <br/>

        </>
    );
}
export default Home;