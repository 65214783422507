import { getHttpEndpoint, getHttpV4Endpoint } from '@orbs-network/ton-access';
// import { TonClient } from 'ton';
import { TonClient, TonClient4} from "@ton/ton";
import { useAsyncInitialize } from './useAsyncInitialize';

export function useTonClient() {
  return useAsyncInitialize(
    async () =>
      new TonClient4({
        endpoint: await getHttpV4Endpoint({ network: 'mainnet' })
        // apiKey: "AHZFSWCMKRHVXJQAAAAA3MIXOICH4XUQVMSHIOB4MQDCTKNMG4MFXPS3T3MMGIDIDYAIPOY"
      })
  );
}

// console.log(process.env.REACT_APP_NETWORK_NAME,"mainnet");
// export function useTonClient() {
//   return useAsyncInitialize(
//     async () =>
//       new TonClient4({
//         endpoint: await getHttpV4Endpoint({ network: 'testnet' }),
//       }) 
//   );
// }
