import axios from 'axios';
// import { useSelector, useDispatch } from 'react-redux';
import store from '../store/index'
import { setMyPresaleToken } from '../store/user/userSlice';
import { init, setStakedNFT } from '../store/contract/contractSlice';
import { init as initScammers, setStakedNFT as setStakedNFTScammers } from '../store/contract/contractScammersSlice';
import MonakiNfts from '../files/MonakiNfts.json' // MAINNET
import DragonNfts from '../files/DragonNfts.json' // TESTNET
// Scammers
import ScammersNftsTest from '../files/ScammersNftsTest.json' // TESTNET
import ScammersNfts from '../files/ScammersNfts.json' // MAINNET
const {dispatch} = store;

// MONAKI Beauties
export const getMyNfts = ( async (owner) => {
    // console.log(config);
    const resData = await axios.post(`${process.env.REACT_APP_BASEURL_API}/my/nfts`,{
        owner: owner
    });
    return resData.data;
})


// MONAKI Scammers
export const getMyNftsScammers = ( async (owner) => {
    // console.log(config);
    const resData = await axios.post(`${process.env.REACT_APP_BASEURL_API}/my/nfts/scammers`,{
        owner: owner
    });
    return resData.data;
})

// MONAKI Beauties
export const getMyPoolNfts = ( (nftsReq) => {
    // console.log("start...", nfts)
    
    const nfts = nftsReq.map((Nftitem)=>{
        // console.log("Loop...", Nftitem.address)
        const nftMeta = process.env.REACT_APP_NETWORK_NAME == 'mainnet' ? 
        MonakiNfts.find(nft => {
            // console.log(nft.address_raw,"==",Nftitem.address)
            return nft.address_raw === Nftitem.address
        }) : 
        DragonNfts.find(nft => {
            // console.log(nft.address_raw,"==",Nftitem.address)
            return nft.address_raw === Nftitem.address
        }) ;
        
        Nftitem.name = nftMeta.name;
        Nftitem.image = nftMeta.image;
        return Nftitem
    })
        
    if(nfts.length){
        let obj = {
            days7: [],
            days14: [],
            days30: []
        }

        let totalEarning = 0;
        const totalNFT = nfts.length;

        nfts.forEach(item => {
            
            totalEarning += item.rewards;
            switch(item.days){
                case 7 :
                    obj.days7.push(item);
                    break;
                case 14 :
                    obj.days14.push(item);
                    break;
                case 30 :
                    obj.days30.push(item);
                    break;
                default :
                    break;
            }
        });

        dispatch(init(obj));
        dispatch(setStakedNFT({totalNFT: totalNFT, earning: totalEarning}))

    }
   
    return nfts;
})

// MONAKI Scammers
export const getMyPoolNftsScammers = ( (nftsReq) => {
    // console.log("start...", nfts)
    
    const nfts = nftsReq.map((Nftitem)=>{
        // console.log("Loop...", Nftitem.address)
        const nftMeta = process.env.REACT_APP_NETWORK_NAME == 'mainnet' ? 
        ScammersNfts.find(nft => {
            // console.log(nft.address_raw,"==",Nftitem.address)
            return nft.address_raw === Nftitem.address
        }) : 
        ScammersNftsTest.find(nft => {
            // console.log(nft.address_raw,"==",Nftitem.address)
            return nft.address_raw === Nftitem.address
        }) ;
        
        Nftitem.name = nftMeta.name;
        Nftitem.image = nftMeta.image;
        return Nftitem
    })
        
    if(nfts.length){
        let obj = {
            days7: [],
            days14: [],
            days30: []
        }

        let totalEarning = 0;
        const totalNFT = nfts.length;

        nfts.forEach(item => {
            
            totalEarning += item.rewards;
            switch(item.days){
                case 7 :
                    obj.days7.push(item);
                    break;
                case 14 :
                    obj.days14.push(item);
                    break;
                case 30 :
                    obj.days30.push(item);
                    break;
                default :
                    break;
            }
        });

        dispatch(initScammers(obj));
        dispatch(setStakedNFTScammers({totalNFT: totalNFT, earning: totalEarning}))

    }
   
    return nfts;
})
