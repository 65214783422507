//@ts-nocheck
import monaki from "./../../assets/img/monaki_scammers.png";
import frame from "./../../assets/img/back_frame_scammers.png";
import "./ScammersStyle.css";
import { useState, useEffect } from 'react';
import { useStakingContractScammers } from "../../hooks/useStakingContractScammers";

const Scammers = () =>{
    
  const { staked } = useStakingContractScammers();
  const [isRetrive, setIsRetrive] = useState(false)
    const [data, setData] = useState({
        total: 4444,
        staked: 0,
        percentage:0
    });

    useEffect(()=>{
        if(isRetrive) return;
        staked?.then((totalStakedData)=> {
            const totalNFT = 4444;
            if(totalStakedData.stakedItems.size){
                const totalStaked = totalStakedData.stakedItems.size;
                const percentage = (totalStaked/totalNFT)*100;
                
                const obj = {
                    total: totalNFT, staked: totalStaked, percentage: percentage.toFixed(2)
                }
                setIsRetrive(true)
                setData(obj)
            }
            
        })
        // staked().then((resData)=>{
        //     console.log("Staked",resData)
        //     // if(resData.success){
        //     //     setData(resData.data)
        //     // }
        // })
    },[staked])
    
    return(
        <>
            <div className="scammers site-paddding">
                <div className="container-fluid padding-5em home-section-1">
                    <h2 className="home-title">STAKE YOUR THE SCAMMERS NFT & MINE $(MONK)</h2>
                    <p>We will automatically stake the MONAKI NFTs in your wallet and reward
                    <br />$MONK to your account after connecting wallet.</p>
                    {/* <button className="btn btn-white">CONNECT WALLET</button> */}
                </div>
            </div>
            <div className="container-fluid p-0 counter-block-section">
                
                <div className="counter-block-parent">
                    <div className="row row-cols-3 row-cols-sm-3 row-cols-lg-3 align-items-center counter-block ">
                        <div className="col block">
                            <h3>{data?.total ? data?.total : 999 }</h3>
                            <p className="m-0">THE SCAMMERS LIVE ON TON </p>
                            <hr className="scammers-block-hr"/>
                        </div>
                        <div className="col block ">
                            <h3>{data?.staked ? data?.staked : 0 }</h3>
                            <p className="m-0">THE SCAMMERS STAKED</p>
                            <hr className="scammers-block-hr"/>
                        </div>
                        <div className="col block ">
                            <h3>{data?.percentage ? data?.percentage : 0 }%</h3>
                            <p className="m-0">THE SCAMMERS STAKED</p>
                        </div>
                    </div>
                </div>
                <img src={frame} alt=""  className="d-inline-block align-text-top monaki-img" />
            </div>
            <img src={monaki} alt=""  className="d-inline-block align-text-top monaki-img" />
            <br/>
        </>
    );
}
export default Scammers;