import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/userSlice'
import contractReducer from './contract/contractSlice'
import contractScammersReducer from './contract/contractScammersSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    contract: contractReducer,
    contractScammers: contractScammersReducer,
  },
  
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})