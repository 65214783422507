const Footer = () =>{
    return(
        <>
            <footer>
                <div className="container-fluid align-items-center">
                    <div className="row parent-block">
                        <div className="col-lg-7 p-1">
                            <div className="dicover">
                                <div className="row row-cols-1">
                                    <h3>DISCOVER MONAKI</h3>
                                </div>
                                <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3 mt-1">
                            <div className="col">
                                <a href="https://www.monaki.life/about-en">ABOUT PROJECT</a>
                            </div>
                            <div className="col">
                                <a href="https://www.monaki.life/manifesto-en">MANIFESTO</a>
                            </div>
                            {/* <div className="col">
                                <a href="https://www.monaki.life/casino-1">CASINO MONAKI</a>
                            </div> */}
                        </div>
                        <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3">
                            <div className="col">
                                <a href="https://www.monaki.life/roadmap-en">ROADMAP</a>
                            </div>
                            <div className="col">
                                <a href="https://www.monaki.life/team-en">TEAM</a>
                            </div>
                            {/* <div className="col">
                                <a href="https://monaki.crashout.io/">PLAY GAMES</a>
                            </div> */}
                        </div>
                            </div>
                        </div>
                        <div className="col-lg-5 p-1 footer-btn-warp">
                            <div className="followus">
                                <div className=" row-cols-1">
                                    <h3>FOLLOW US</h3>
                                </div>
                                <div className=" row-cols-2 row-cols-lg-5 mt-3 ">
                                    <a href="https://t.me/monaki_en" target="_blank" className="btn btn-transparent">Channel EN</a>
                                    <a href="https://t.me/monaki_ton" target="_blank" className="btn btn-transparent ms-lg-3">Channel RU</a>
                                    <a href="https://twitter.com/Monaki_nft" target="_blank" className="btn btn-transparent ms-lg-3">Twitter</a>
                                </div>
                                <div className=" copyrights">
                                <p className="p-0">MONAKI ©Copyright 2023. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>    
        </>
    );
}
export default Footer;