//@ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import StakeNFT from "./StakeNFT";

const NftsContainer = (props: any) =>{
    const pool = useSelector((store) => store.contract.pool)
    const { days } = props;
  
    return(<>
        {/* { nfts?.map((nft: any, index : number) => { */}
        {
           

            pool && (
                days == 7 ?  
                    pool.days7.map( ( nft, index) => 
                        <StakeNFT item={nft} key={index} />
                    ): 
                days == 14?
                    pool.days14.map( ( nft, index) => 
                        <StakeNFT item={nft} key={index} />
                    ):
                days == 21?
                    pool.days21.map( ( nft, index) => 
                        <StakeNFT item={nft} key={index} />
                    ):
                days == 30?
                    pool.days30.map( ( nft, index) => 
                        <StakeNFT item={nft} key={index} />
                    ):
                ''
            )


            

        }  
         
    </>)
}
export default NftsContainer;