//@ts-nocheck
import Logo from "./../assets/img/logo.png";
import Logo2 from "./../assets/img/logo2.png";
import "./layout.css";
import {Link, useLocation} from "react-router-dom";
import React, { useEffect } from 'react';
// @ts-ignore
import  "./LayoutStyle.css"
import { TonConnectButton, useTonConnectUI, useTonWallet} from '@tonconnect/ui-react';
// import { getMyPoolNfts } from "../api/api";
// import { useDispatch } from "react-redux";
// import { resetMyNft } from "../store/user/userSlice";
// import { reset } from "../store/contract/contractSlice"
// import { useStakingContract } from "../hooks/useStakingContract";

const MainHeader: React.FC = () => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const wallet = useTonWallet();
    // const dispatch = useDispatch();
    const location = useLocation();
    // const { getStakedNftsByUser } = useStakingContract();

    // const getMyContractData = async() => {
    //     await getStakedNftsByUser()
    // }

    // useEffect(() => {
    //     if(wallet){
    //         getMyContractData();
    //     }
    //     tonConnectUI.onStatusChange(async wallet => {
    //     // console.log("WALEET", wallet);
    //         if (Object.is(wallet, null)) {
    //         }else{
    //             // console.log("start")
    //             getMyContractData();
    //         }
    //     }) }, []);   

    //     useEffect(() => {
    //         if(!wallet) {
    //             dispatch(reset());
    //             dispatch(resetMyNft())
    //         }else{
    //             getMyContractData()
    //         }
    //     },[wallet])
    
        // console.log("location: ",location);   
 return(
    <>
        <nav className="navbar navbar-expand-lg navbar-light">
    <div className="container-fluid align-items-center"> 
        <Link to="/" className="navbar-brand">
            <img src={Logo} alt="" width="65"  className="d-inline-block align-text-top" />
            {location.pathname == '/presale' && <img src={Logo2} alt="" width="65"  className="d-inline-block align-text-top ms-2" /> }
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="float-end-parent">
                <div className={location.pathname == '/presale'? "nav-buttons-parent float-md-end float-none presale" : "nav-buttons-parent float-md-end float-none"}>
                    <div className="float-md-start float-none">
                        <Link to="/beauties" className="btn btn-transparent-black ms-3">Beauties</Link>
                        <Link to="/scammers" className="btn btn-transparent-black ms-3">The Scammers</Link>
                        <a href="https://getgems.io/collection/EQBFlSeuVxqtEKT05YgkA4NC3vLcoBef1zw2Z_HDt1BPGhWM" target="_blank" className="btn btn-transparent-black ms-3">BUY MONAKI</a>
                       
                    </div>
                    {/* <div className="float-none" style={{"display":"contents"}}>
                    <TonConnectButton className="my-button-class" style={{ float: "right" }} />
                    </div> */}
                {/* <button className="btn btn-transparent ms-3 btn-logout">
                    <svg viewBox="0 0 32 29" fill="none"><path d="M18.2767 28.2143L31.9999 14.5L18.2812 0.785675L16.6652 2.39729L27.625 13.3571H0V15.6429H27.6249L16.6607 26.6071L18.2767 28.2143Z" fill="#F5F5F5"/></svg>
                     LOGOUT</button>*/}
                </div> 
            </div>
        </div>
    </div>
    </nav>
    </>
 );
}
export default MainHeader;