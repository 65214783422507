//@ts-nocheck
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
moment.tz.setDefault('UTC');

const DateTme = (props) => {
  const [date, setDate] = useState(props.date)
  const [display, setDisplay] = useState('');
  useEffect(()=>{
    setInterval(() => {
      setDisplay(moment(date).fromNow())
      const currentDate = moment.utc(); 
      const endDate = moment(date);
      const remainingSeconds = endDate.diff(currentDate, 'seconds', true);
    
      if(remainingSeconds < 0){
        props.setDisabled(false)
      }
    }, 1000);
  },[date])
  return (
    <>{moment(props.date).format("YYYY-MM-DD HH:mm")} UTC</>
  )
}

export default DateTme