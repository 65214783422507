//@ts-nocheck
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    ownNFT: 0,
    ownNFTScammers: 0,
  },
  reducers: {
    setOwnNFT: (state, action) => {
        state.ownNFT = action.payload;
    },
    setOwnNFTScammers: (state, action) => {
      state.ownNFTScammers = action.payload;
    },
    resetMyNft: (state, action) => {
      state.ownNFT = 0;
      state.ownNFTScammers = 0;
    },

  },
})



// Action creators are generated for each case reducer function
export const {setOwnNFT, setOwnNFTScammers, resetMyNft } = userSlice.actions

export default userSlice.reducer