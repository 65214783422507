//@ts-nocheck
import { createSlice } from '@reduxjs/toolkit'

export const contractScammersSlice = createSlice({
  name: 'contractScammers',
  initialState: {
    // account: null,
    pool: null,
    stakedNFT: 0,
    earning: 0,
  },
  reducers: {
    init: (state, action) => {
        // console.log(action.payload);
        state.pool = action.payload;
    },
    setStakedNFT: (state, action) => {
      state.stakedNFT = action.payload.totalNFT;
      state.earning = action.payload.earning;
    },
    reset: (state, action) => {
      state.pool = null;
      state.earning = 0;
      state.stakedNFT= 0;
    }

  },
})



// Action creators are generated for each case reducer function
export const { init, setStakedNFT, reset} = contractScammersSlice.actions

export default contractScammersSlice.reducer