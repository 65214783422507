//@ts-nocheck
import Logo from "../../assets/img/logo.png";
import React, { useEffect, useState } from 'react'
import { useTonWallet} from '@tonconnect/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MynftSignle from './MynftSingle';
import { getMyNfts} from '../../api/api';
import Modal from 'react-bootstrap/Modal';
import { resetMyNft, setOwnNFT } from "../../store/user/userSlice";
import { useStakingContract } from "../../hooks/useStakingContract";

const Mynft = (props: any) =>{

  const dispatch = useDispatch();
  const { getStakedNftsByUser, createStake } = useStakingContract();
  const wallet = useTonWallet();
  const [nfts, setNfts] = useState([])
  const [modelNft, setModelNft] = useState();
  const [staking, setStaking] =useState('');

  //MODAL
  const [modelShow, setModelShow] = useState(false);
  

  useEffect(()=>{
    if(wallet){
      fetchNfts();
    }else{
      setNfts([]);
      dispatch(resetMyNft())
    }
    
  },[wallet])

  const fetchNfts = () =>{
      if(wallet){
        
          getMyNfts(wallet.account.address).then((resData)=>{
              if(resData.success){
                const nftsData = resData.data.nft_items.map((item)=>{
                  return item;
                })
                setNfts(resData.data.nft_items);
                dispatch(setOwnNFT(nftsData.length));
              }
              
              
          });
      }
  }

  const stakeNft = (nft, days) => {
      handleModalHide();
      const address = nft.address;
      setStaking(address);
      const resData = createStake(address, days);
      // console.log(resData);
      setTimeout(()=>{
        // console.log("Staking Result....")
        setStaking('');
        getStakedNftsByUser();
        fetchNfts();
      }, 30000);
  }


    const handleModel = (nft) => {
        setModelNft(nft);
        setModelShow(true);
    }

    const handleModalHide = () => {
      setModelNft('');
      setModelShow(false);
    }
 
    return(<>
        {
            nfts && (
                nfts.map( (nft) => 
                <MynftSignle key={nft.address} nft={nft} staking={staking == nft.address ? true : false }  handleClick={()=> handleModel(nft)}/>
                )
            )
                 
        }
        { modelNft && 
          <Modal
          size="sm"
          show={modelShow}
          onHide={() => handleModalHide() }
          className="nft-model"
        >
   
          <Modal.Body closeButton>
          <div className="card col align-self-start">
                <div className="mb-2">
                    <img src={Logo} alt="avatar" className="avatar border border-white rounded-circle" />
                    <span className="creator ms-3">
                        {modelNft.metadata.name}
                    </span>
                  </div>
                <div className="img-placeholder">
                  <img src={modelNft.metadata.image} alt="Monaki: beautes #12" className="img-fluid rounded-3 " id="image"  />
                </div>
                <div className="card-body p-0">
                  <h5 className="card-title mt-2">Monaki</h5>
                  <p className="card-text">{modelNft.metadata.name}</p>
                  {
                    staking ? 
                    <h5 className="card-title mt-2">Verifying...</h5>:
                    <div className="nav-buttons-parent">
                     
                      <button className="btn btn-transparent-black mt-2" onClick={() => stakeNft(modelNft, 7)}>STAKE 7 DAYS</button>
                      
                      <button className="btn btn-transparent-black mt-2" onClick={() => stakeNft(modelNft, 14)} >STAKE 14 DAYS</button>
                      {/* <button className="btn btn-transparent-black mt-2" onClick={() => stakeNft(modelNft, 21)} >STAKE 21 DAYS</button> */}
                      <button className="btn btn-transparent-black mt-2" onClick={() => stakeNft(modelNft, 30)} >STAKE 30 DAYS</button>
                    </div>
                  }
               </div>
            </div>
          </Modal.Body>
        </Modal>
        }
        
    </>);
}
export default Mynft;



{/* <div className="image-hover">
        <div className="mb-2">
            <img src={Logo} alt="avatar" className="avatar border border-white rounded-circle" />
            <span className="creator ms-3">
              MONAKI : Beaties #123
            </span>
            {/* <button className="btn btn-radio mt-4" onClick={()=>{stake5Minut(nftd?.mint)}}>STAKE NFT FOR 5 MINUTE</button> 
            {/* <button className="btn btn-radio mt-4" onClick={()=>{stakeNft()}}>STAKE NFT FOR 7 DAYS </button>
            <button className="btn btn-radio mt-2" onClick={()=>{stakeNft()}}>STAKE NFT FOR 14 DAYS </button>
            <button className="btn btn-radio mt-2" onClick={()=>{stakeNft()}} >STAKE NFT FOR 21 DAYS</button>
            <button className="btn btn-radio mt-2 mb-2" onClick={()=>{stakeNft()}} >STAKE NFT FOR 30 DAYS</button> 
            {/* <center><button className="btn btn-black no-hover">STAKE NFT</button></center> 
          </div>
    </div> */}