//@ts-nocheck
import { useEffect, useState } from 'react';
import LoaderView from "./Loader/Loader";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Test from '../../page/Test/Test';

const StakeSummeryScammers = () =>{
    const contractStore = useSelector( (store) => store.contractScammers);
    const userData = useSelector( (store) => store.user);
    const [Nftlist, setNftList] = useState<any>([]);
    const [nftData, setNftData] = useState<any>([]);
    const [stakeRes, setStakeRes] = useState<any>(Date.now());
    const [userAllStake, setUserAllStake] = useState<any>([]);
    const [totalNft, setTotalNft] = useState(0);
    const [totalNftStake, setTotalNftStake] = useState(0);
    const [totalReward, setTotalReward] = useState(640);
    const [loading, setLoading] = useState<boolean>(false);

    
  
 return(
    <>  
     {loading && <LoaderView /> }
            <div className="col-lg-4 summery-block-mobile">
                <div className='summery-block' style={{background:"#000000"}}>
                    <div className="col block">
                        <p>THE SCAMMERS OWNED</p>
                        <h3>{userData.ownNFT}</h3>
                    </div>
                    <div className="col block">
                        <p>TOTAL THE SCAMMERS STAKED</p>
                        <h3>{contractStore.stakedNFT}</h3>
                    </div>
                    <div className="col block">
                        {/* <Test /> */}
                        {/* <button className="btn btn-white" onClick={()=>{claimAllRewardHandler()}}>CLAIM ALL REWARD</button> */}
                    </div>
                    <div className="col block mt-5">
                        <p>REWARD BALANCE</p>
                        <h3>{contractStore.earning} <span className='token-currency'>$MONK</span></h3>
                    </div>
                    
                </div>
            </div>
    </>
 );
}
export default StakeSummeryScammers;