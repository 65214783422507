import React from "react";
import './Loader.css';
const LoaderView = () => {
    return(
      <>
        <div  className='cover-spin'></div>
      </>
    )
  };
  
export default LoaderView;